import { ClockCircleOutlined, FileDoneOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal, Space, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { FC, useState } from 'react';
import liveIcon from '../../assets/images/live-icon.png';
import lineIcon from '../../assets/images/line-icon.png';
import telegramIcon from '../../assets/images/telegram-icon.png';
import { useHistory } from 'react-router-dom';
import { utils } from '../../services/utils';
import './detail-sider.css';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from '../../stores/auth.slice';
import Notification from '../notification/notification';
import { ChangePassword } from '../../services/services';
import { lottoLink } from './lotto-link';


const { Text, Link, Title } = Typography;

type Props = {
    time: number,
    credit: number
}

const DetailSider: FC<Props> = ({ time, credit }) => {

    let history = useHistory();
    const dispatch = useDispatch<any>();
    const [form] = Form.useForm();
    const [profile, setProfile] = useState({ nickname: localStorage.getItem("name") })
    const [role, setRole] = useState(localStorage.getItem("role"))
    const [changePasswordModal, setChangePasswordModal] = useState(false);

    const { loading } = useSelector((state: any) => {
        return {
            loading: state.auth.loading
        }
    });

    const onLogout = () => {
        localStorage.clear();
        setTimeout(() => {
            history.push("/");
        }, 1500)
    }

    const onChangePasswordOpen = () => {
        setChangePasswordModal(true);
    }

    const onsubmitChangePassword = () => {
        form.validateFields().then(async (values) => {
            dispatch(setIsLoading(true));
            try {
                await dispatch(ChangePassword({ ...values })).unwrap();
                dispatch(setIsLoading(false));
                setChangePasswordModal(false);
                Notification("success", "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว");
            } catch (error) {
                dispatch(setIsLoading(false));
                Notification("error", "ไม่สามารถเปลี่ยนรหัสผ่านได้กรุณาลองใหม่");
            }
        });
    }

    return (
        <div>

            <div className="user-detail">
                <Space>
                    <ClockCircleOutlined style={{ fontSize: 20 }} />
                    <Text style={{ color: '#fff' }}>{moment(time).format("DD/MM/YYYY HH:mm:ss")}</Text>
                </Space>

                <div style={{ margin: '15px 5px' }}>
                    <Space size={15}>
                        <Text strong style={{ color: '#fff', paddingBottom: 2 }}>ผู้ใช้งาน</Text>
                        <Text style={{ color: '#fff' }}>{profile.nickname}</Text>
                    </Space>
                    <br />
                    <Space size={30}>
                        <Text strong style={{ color: '#fff', paddingBottom: 2 }}>ระดับ</Text>
                        <Text style={{ color: '#fff' }}>{role}</Text>
                    </Space>
                    <br />
                    <Space size={22}>
                        <Text strong style={{ color: '#fff', paddingBottom: 2 }}>เครดิต</Text>
                        <Text style={{ color: '#fff' }}>{utils.numberWithCommas(credit + "")}</Text>
                    </Space>
                </div>

                <Button block style={{ backgroundColor: '#1e1e2d', color: 'white', borderColor: 'white', marginBottom: 10 }} onClick={() => onChangePasswordOpen()}><LockOutlined /> เปลี่ยนรหัสผ่าน</Button>
                <Button block style={{ backgroundColor: '#4547FF', color: 'white', borderColor: '#4547FF', marginBottom: 10 }} onClick={() => onLogout()}><LogoutOutlined /> ออกจากระบบ</Button>
            </div>
            <Divider style={{ backgroundColor: '#BDCCDB', margin: '5px 0px 10px 0px' }} />

            <div>
                <div className="ad">
                    <Space size={18}>
                        <FileDoneOutlined style={{ fontSize: 20 }} />
                        <Link href={lottoLink()} target="_blank" style={{ color: '#fff' }}>
                            ตรวจสอบผลรางวัล
                        </Link>
                    </Space>
                    <br />
                    {/* <Space>
                        <img src={liveIcon} style={{ width: 30, height: 20 }} alt="live lotto" />
                        <Link href="#" target="_blank" style={{ color: '#fff' }}>
                            LIVE ผลหวย
                        </Link>
                    </Space> */}
                </div>

                {/* <div className="contact">
                    <Title level={5} style={{ color: '#fff' }}>ติดต่อเรา</Title>
                    <Divider style={{ backgroundColor: '#fc1f54', margin: '5px 0px 10px 0px' }} />
                    <Space style={{ marginBottom: 5 }}>
                        <img src={lineIcon} style={{ width: 20, height: 20 }} alt="line lotto" />
                        <Link href="#" target="_blank" style={{ color: '#fff' }}>
                            @REALLOTTO
                        </Link>
                    </Space>
                    <br />
                    <Space>
                        <img src={telegramIcon} style={{ width: 20, height: 20 }} alt="telegram lotto" />
                        <Link href="#" target="_blank" style={{ color: '#fff' }}>
                            @REALLOTTO
                        </Link>
                    </Space>
                </div> */}

            </div>

            <Modal title="เปลี่ยนรหัสผ่าน"
                maskClosable={false}
                open={changePasswordModal}
                footer={false}
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        style={{ minWidth: 420 }}
                        name="change-password"
                    >
                        <Form.Item
                            name="oldPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณาใส่รหัสผ่านเก่า',
                                },
                            ]}
                        >
                            <Input className="input-password" maxLength={32} prefix={<LockOutlined />} type="password" placeholder="รหัสผ่านเก่า" />
                        </Form.Item>

                        <Form.Item
                            name="newPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'กรุณาใส่รหัสผ่านใหม่',
                                },
                            ]}
                        >
                            <Input className="input-password" maxLength={32} prefix={<LockOutlined />} type="password" placeholder="รหัสผ่านใหม่" />
                        </Form.Item>
                        <Form.Item>
                            <div style={{ textAlign: 'right' }}>
                                <Space>
                                    <Button onClick={() => setChangePasswordModal(false)}>ยกเลิก</Button>
                                    <Button onClick={() => onsubmitChangePassword()} type="primary">บันทึก</Button>
                                </Space>

                            </div>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </div>
    )
}

export default DetailSider;