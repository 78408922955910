import { Spin, Typography } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import MainLayout from '../../components/main-layout/main-layout';

const { Title, Text, Paragraph } = Typography;

const Rules: FC = () => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadComponent = setTimeout(() => {
            setLoading(false);
        }, 1000);

        // return () => {
        //     clearTimeout(loadComponent);
        // }
    }, []);

    return (
        <Spin spinning={loading}>
            <MainLayout menu="rules">
                <Typography style={{ fontSize: 16 }}>
                    <Title>กฎ และ กติกา</Title>
                    <Paragraph>
                        <Text strong>Xonow Lotto</Text> ยึดเอาข้อมูลที่ เอเย่นต์และ สมาชิกทุกคน บนหน้าเว็บไซต์เป็นหลัก กรุณาตรวจสอบรายการเล่นและ พิมพ์เก็บไว้เป็นหลักฐานเสมอ
                        หากมีข้อสงสัยในการตั้งค่าต่างๆ และต้องการความช่วยเหลือ โปรดติดต่อต้นสายของท่าน หากมีข้อผิดพลาดที่เกิดขึ้นกับระบบ
                        โปรดแจ้งทางต้นสายของท่าน
                        ก่อนออกผลรางวัล มิเช่นนั้นบริษัท จะถือเอารายการแทงและข้อมูลที่เกิดขึ้นในเว็บไซต์เป็นหลักเสมอ
                    </Paragraph>
                </Typography>
            </MainLayout>
        </Spin>
    )
}

export default Rules;