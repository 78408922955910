import moment from "moment";

const lotto = [
    {
        name: 'China Vip (Evening)',
        date: moment().format("YYYY-MM-DD 14:25:00"),
        link: 'https://shenzhenindex.com/'
    },
    {
        name: 'China Vip (Morning)',
        date: moment().format("YYYY-MM-DD 10:05:00"),
        link: 'https://shenzhenindex.com/'
    },
    {
        name: 'English Vip (Morning)',
        date: moment().format("YYYY-MM-DD 21:50:00"),
        link: 'https://lottosuperrich.com/'
    },
    {
        name: 'Hangseng Vip (Evening)',
        date: moment().format("YYYY-MM-DD 15:25:00"),
        link: 'https://hangsengvip.com/'
    },
    {
        name: 'Hangseng Vip (Morning)',
        date: moment().format("YYYY-MM-DD 10:35:00"),
        link: 'https://hangsengvip.com/'
    },
    {
        name: 'Hanoi Extra',
        date: moment().format("YYYY-MM-DD 22:30:00"),
        link: 'https://www.xosoextra.com/'
    },
    {
        name: 'Hanoi Star',
        date: moment().format("YYYY-MM-DD 12:30:00"),
        link: 'https://minhngocstar.com/'
    },
    {
        name: 'Hanoi TV',
        date: moment().format("YYYY-MM-DD 14:30:00"),
        link: 'https://minhngoctv.com/'
    },
    {
        name: 'Lao Star VIP',
        date: moment().format("YYYY-MM-DD 22:00:00"),
        link: 'http://www.laostarsvip.com/'
    },
    {
        name: 'Lao TV',
        date: moment().format("YYYY-MM-DD 10:30:00"),
        link: 'https://lao-tv.com/'
    },
    {
        name: 'Lao VIP',
        date: moment().format("YYYY-MM-DD 21:30:00"),
        link: 'https://www.laosviplot.com/'
    },
]

export const lottoLink = () => {
    var temp = lotto.map(d => Math.abs(new Date().getTime() - new Date(d.date).getTime()));
    var idx = temp.indexOf(Math.min(...temp));
    return lotto[idx].link;
}