import { configureStore } from "@reduxjs/toolkit";
import mainSlice from "./main.slice";
import authSlice from "./auth.slice";

export const store = configureStore({
    reducer: {
        main: mainSlice,
        auth: authSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})