import { createSlice } from "@reduxjs/toolkit";
import { ChangePassword } from "../services/services";

const initialState = {
    loading: false,
    // error: 
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ChangePassword.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(ChangePassword.fulfilled, (state, action) => {
                state.loading = false;
                // const newData = { ...action.payload.data };
                // console.log(action.payload.data.credit);
                
                // state.credit = action.payload.data.credit;
            })
            .addCase(ChangePassword.rejected, (state, action) => {
                state.loading = false;
                // state.error = action.payload;
            })
    }
});

export const { setIsLoading } = authSlice.actions;
export default authSlice.reducer;